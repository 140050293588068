import { Link } from "gatsby";
import * as React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SEO from "../components/SEO";
import "../styles/voorwaarden.scss";

const HostingVoorwaarden: React.FC<any> = (props) => {
    const { location } = props;

    const isSelected = (path: string) => {
        if (location.pathname.replace("/", "") === path) {
            return "voorwaarden-link-is-selected";
        }
        return ""
    }

    return (
        <>
            <SEO
                title="Voorwaarden - Leading Apps"
                description={"Leading App's algemene voorwaarden hosting"}
                article={false}
            />

            <Header />

            <section id="doelgericht">

                <div className="section-spacer"></div>

                <div className="voorwaarden g-0 width-wrapper-medium mpb-sm">
                    <div className="col-md-10">

                        <div className="voorwaarden">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="mb-5">
                                        <h1 className="mb-4 bold"><b>Algemene Voorwaarden Hosting</b></h1>
                                        <p className="italics grey mb-5">Versie 14 juni 2021</p>
                                        <p className="bold">Leading Apps, gevestigd aan Pasakker 73, 5712 HS te Someren</p>
                                    </div>

                                    <div className="mt-3 pt-3">
                                        <h2 className="bold mb-3 ">1. Definities</h2>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Definitie</th>
                                                    <th>Uitleg</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Dataverkeer
                                                    </td>
                                                    <td>
                                                        verkeer van digitale informatie van en naar de Diensten die een Klant bij Leading Apps heeft afgesloten (website, weblog, e-mail, etc).
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Diensten
                                                    </td>
                                                    <td>
                                                        de diensten die door Leading Apps ten behoeve van Klanten worden verricht.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Domeinnaam
                                                    </td>
                                                    <td>
                                                        het adres waarop de Website van een Klant op het internet gevonden kan worden.

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Klant
                                                    </td>
                                                    <td>
                                                        de natuurlijke of rechtspersoon die met Leading Apps een Overeenkomst heeft gesloten.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Opdrachtgever
                                                    </td>
                                                    <td>
                                                        degene die gebruik maakt van de diensten van Leading Apps, aan Leading Apps werkzaamheden opdraagt of Leading Apps verzoekt een offerte uit te brengen.

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Leading Apps
                                                    </td>
                                                    <td>
                                                        Leading Apps gevestigd aan het Kloosterweg 39 te (5711 JG) Someren, ingeschreven bij de Kamer van Koophandel onder nummer 82956472.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Overeenkomst
                                                    </td>
                                                    <td>
                                                        de Overeenkomst tussen Leading Apps en een Klant op grond
                                                        waarvan Leading Apps Diensten verricht.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Persoonsgegevens
                                                    </td>
                                                    <td>
                                                        elk gegeven betreffende een geïdentificeerde of identificeerbare natuurlijke persoon.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Systeem
                                                    </td>
                                                    <td>
                                                        computer- en aanverwante apparatuur waarvan Leading Apps bij de uitvoering van de overeenkomst gebruik maakt.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Hosting
                                                    </td>
                                                    <td>
                                                        het aanbieden van een breedbandige verbinding via een server met het internet.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Diskspace
                                                    </td>
                                                    <td>
                                                        harde schijfruimte op een webserver, die Leading Apps, al dan niet door tussenkomst van een derde, ter beschikking stelt aan de Klant ten behoeve van het versturen en ontvangen van e-mail, het aanhouden van een website of webserver en overige diensten van Leading Apps.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Website
                                                    </td>
                                                    <td>
                                                        een of meerdere geïntegreerde internetpagina's, die worden voorafgegaan door een homepage.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3">2. Toepasselijkheid</h2>
                                        <ol>
                                            <li>Deze algemene voorwaarden zijn van toepassing op de totstandkoming, de inhoud en de nakoming van alle tussen de Klant en Leading Apps gesloten overeenkomsten. Indien partijen van enige bepaling van deze voorwaarden wensen af te wijken, dient zulks schriftelijk te worden vastgelegd. De toepasselijkheid van eventuele inkoop- of andere voorwaarden van Opdrachtgever wordt uitdrukkelijk van de hand gewezen.</li>
                                            <li>De onderhavige voorwaarden zijn eveneens van toepassing op alle overeenkomsten met Leading Apps, voor de uitvoering waarvan derden dienen te worden betrokken.</li>
                                            <li>Wanneer door Leading Apps gedurende korte of langere tijd al dan niet stilzwijgend afwijkingen van onderhavige algemene voorwaarden zijn toegestaan, laat dat onverlet haar recht alsnog directe en strikte naleving van deze voorwaarden te eisen. De Klant kan geen rechten ontlenen aan de wijze waarop Leading Apps de onderhavige voorwaarden toepast.</li>
                                            <li>Indien een of meer van de bepalingen van onderhavige algemene voorwaarden of enige andere Overeenkomst met Leading Apps in strijd mochten zijn met een dwingende wetsbepaling of enig toepasselijk rechtsvoorschrift, zal de betreffende bepaling komen te vervallen en zal deze worden vervangen door een door Leading Apps vast te stellen nieuwe, rechtens toelaatbare en vergelijkbare bepaling.</li>
                                            <li>De Klant met wie eenmaal op de onderhavige voorwaarden werd gecontracteerd, wordt geacht stilzwijgend met de toepasselijkheid van deze voorwaarden op een later met Leading Apps gesloten Overeenkomst in te stemmen.</li>
                                            <li>In geval van strijd tussen de inhoud van een tussen de Klant en Leading Apps gesloten Overeenkomst en de onderhavige voorwaarden, prevaleert de inhoud van de Overeenkomst.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">3. Aanbiedingen en offertes</h2>
                                        <ol>
                                            <li>Alle aanbiedingen en offertes van Leading Apps zijn herroepelijk en worden vrijblijvend gedaan, tenzij schriftelijk anders wordt aangegeven.</li>
                                            <li>De Klant staat in voor de juistheid en volledigheid van de door of namens hem aan Leading Apps opgegeven maten, eisen, specificaties van de prestatie en andere gegevens waarop Leading Apps haar aanbieding baseert.</li>
                                            <li>Een samengestelde prijsopgave verplicht Leading Apps niet tot het verrichten van een gedeelte van de opdracht tegen een overeenkomstig gedeelte van de opgegeven prijs.</li>
                                            <li>De inhoud van de opdracht wordt uitsluitend bepaald door de in de offerte en opdrachtbevestiging gegeven omschrijving van de opdracht.</li>
                                            <li>De prijzen in de aanbiedingen en offertes van Leading Apps zijn exclusief BTW en andere heffingen van overheidswege, alsmede eventuele in het kader van de opdracht te maken kosten, waaronder verzend- en administratiekosten, tenzij anders aangegeven.</li>
                                            <li>Indien de aanvaarding (al dan niet op ondergeschikte punten) afwijkt van het in de offerte of de aanbieding opgenomen aanbod dan is Leading Apps daaraan niet gebonden. De overeenkomst komt dan niet overeenkomstig deze afwijkende aanvaarding tot stand, tenzij Leading Apps anders aangeeft.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">4. Prijzen en prijswijziging</h2>
                                        <p>Leading Apps is te allen tijde gerechtigd de hoogte van de overeengekomen vergoedingen en kosten eenzijdig te wijzigen. Het wijzigen van de vergoedingen en kosten levert geen grond op voor ontbinding van de overeenkomst door de Klant, tenzij sprake is van een prijsverhoging van meer dan 10%, in welk geval de Klant gerechtigd is de Overeenkomst met inachtneming van de geldende opzegtermijn op te zeggen.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">5. Verplichtingen van de klant (gebruiksvoorwaarden)</h2>
                                        <ol>
                                            <li>De Klant zal zich opstellen en zich gedragen conform hetgeen van een verantwoordelijk en zorgvuldig internetgebruiker verwacht mag worden.</li>
                                            <li>De Klant stelt Leading Apps zo spoedig mogelijk in kennis van wijzigingen in relevante gegevens. Deze wijzigingen worden door Leading Apps automatisch doorgegeven aan de Stichting Internet Domeinregistratie Nederland (SIDN).</li>
                                            <li>De Klant is aansprakelijk voor al het gebruik (ook dat door eventuele derden) dat via zijn account van de Leading Apps Diensten wordt gemaakt.</li>
                                            <li>De Klant onthoudt zich ervan overige Klanten of internetgebruikers te hinderen of schade toe te brengen aan het Systeem. Het is de Klant verboden processen of programma's, al dan niet via het Systeem, te gebruiken waarvan de Klant weet of redelijkerwijs kan vermoeden dat zulks Leading Apps, overige klanten of internetgebruikers kan hinderen of schade kan toebrengen. Het is de Klant slechts toegestaan processen of programma 's te gebruiken indien er een directe, door Leading Apps toegestane, verbinding met het Systeem bestaat.</li>
                                            <li>Het is de Klant niet toegestaan het Systeem en de Diskspace te gebruiken voor handelingen en/of gedragingen die in strijd zijn met toepasselijke wettelijke bepalingen, de algemeen aanvaarde gedragsregels op het Internet zoals vastgelegd in de Request for Comments (RFC) 1855 (https://www.ietf.org/rfc/rfc1855.txt), de richtlijnen van de Reclame Code Commissie, de Overeenkomst of deze algemene voorwaarden. Hieronder vallen onder meer, maar niet uitsluitend, de navolgende handelingen en gedragingen:
                                                <ul>
                                                    <li>het ongevraagd verzenden van grote hoeveelheden e-mail met dezelfde inhoud en/of het ongevraagd in grote aantallen nieuwsgroepen op het internet posten van een bericht met dezelfde inhoud ('spamming');</li>
                                                    <li>het handelen in strijd met rechten van intellectuele of industriële eigendom (databankrechten daaronder begrepen) van derden;</li>
                                                    <li>het openbaar maken of verspreiden van (kinder)pornografie;</li>
                                                    <li>seksuele intimidatie of het op andere wijze lastig vallen van personen;</li>
                                                    <li>het zonder toestemming binnendringen van andere computers of computersystemen op het Internet ('hacken').</li>
                                                    <li>In het geval dit artikel wordt geschonden, behoudt Leading Apps zich het recht voor de betreffende autoriteit in te lichten en inloggegevens door te geven aan die autoriteit. Leading Apps behoudt zich het recht voor om betaling te vereisen voor het werk dat men heeft geleverd om dergelijke informatie door te geven aan de betreffende autoriteit.</li>
                                                </ul>
                                            </li>
                                            <li>Het is de Klant niet toegestaan zijn of haar account, of andere uit de Overeenkomst voortvloeiende rechten aan derden over te dragen of aan derden in gebruik te geven, tenzij Leading Apps hiervoor schriftelijk uitdrukkelijk toestemming heeft gegeven.</li>
                                            <li>De Klant draagt zorg voor de noodzakelijke hard- en software, randapparatuur en verbindingen om toegang tot het Systeem mogelijk te maken.</li>
                                            <li>Leading Apps is, zonder dat Leading Apps tot enige schadevergoeding jegens
                                                de Klant zal zijn gehouden, gerechtigd zonder nadere aankondiging de aan de Klant verstrekte aansluiting op het Systeem onmiddellijk buiten gebruik te stellen, op te schorten of aan deze aansluiting nadere voorwaarden te stellen, indien en zolang de Klant handelt (of vermoed wordt dit te doen) in strijd met het gestelde in dit artikel.
                                            </li>
                                            <li>De Klant vrijwaart Leading Apps tegen alle aanspraken van derden ter zake van schade of anderszins, op enigerlei wijze ontstaan door het gebruik van de Klant van het account, het Systeem of internet, dan wel door het niet nakomen van de Klant van zijn verplichtingen uit de Overeenkomst of deze algemene voorwaarden.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">6. Unmetered traffic hosting</h2>
                                        <ol>
                                            <li>Tenzij uitdrukkelijk anders in de Overeenkomst overeengekomen, geldt de regel dat dataverkeer per hosting plan is beperkt. Indien het verkeer van de Klant de overeengekomen hoeveelheid dataverkeer overschrijdt, behoudt Leading Apps zich het recht voor om een afzonderlijk tarief voor het overschrijdende dataverkeer in rekening te brengen.</li>
                                            <li>Indien het verkeer van een Klant het verkeer van andere klanten verstoort, behoudt Leading Apps zich het recht voor om de account van de eerstgenoemde Klant te beperken dan wel af te sluiten zonder voorafgaande kennisgeving. Leading Apps behoudt zich het absolute recht voor om te besluiten of verkeer wel of niet excessief is. Indien een account wordt gesloten, wordt geen restitutie verleend op de abonnementsgelden die vooruit zijn betaald. Leading Apps is niet aansprakelijk voor vergoeding van enige schade als gevolg van een ontzegging of beperking.</li>
                                            <li>Leading Apps behoudt zich het recht te oordelen wat binnen de aanvaardbare limieten valt. Wanneer blijkt dat een site niet aan de gestelde limiet voor dataverkeer voldoet, zal de Klant door Leading Apps worden verzocht tot een upgrade.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">7. Aanvraag domeinnamen</h2>
                                        <ol>
                                            <li>Leading Apps bemiddelt slechts bij het registreren van een Domeinnaam voor de Klant en zal de aanvraag voor het betreffende domein deponeren bij de verantwoordelijke instantie (SIDN). Indien de verantwoordelijke instantie de aanvraag niet honoreert kan Leading Apps hiervoor nimmer aansprakelijk worden gesteld.</li>
                                            <li>Bij aanvraag en registratie van een Domeinnaam dient rekening gehouden te worden met een doorlooptijd van enkele dagen. Indien een aangevraagde Domeinnaam door de Klant niet meer beschikbaar is op het moment dat Leading Apps de aanvraag indient bij de desbetreffende instantie en deze wel beschikbaar was op het moment dat de Klant de aanvraag indiende bij Leading Apps, kan Leading Apps op geen enkele manier aansprakelijk worden gesteld voor het niet kunnen registeren van een Domeinnaam.</li>
                                            <li>Registratie van de Domeinnaam vindt plaats op naam van Leading Apps. De Klant is volledig verantwoordelijk voor het gebruik van de Domeinnaam. De Klant vrijwaart Leading Apps tegen iedere aanspraak van derden in verband met de registratie en het gebruik van de Domeinnaam.</li>
                                            <li>Registratie van de Domeinnaam vindt plaats nadat de Klant de factuur voor de betreffende Dienst heeft voldaan.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">8. Intellectuele eigendomsrechten</h2>
                                        <ol>
                                            <li>Alle rechten van intellectueel of industrieel eigendom (databankrechten inbegrepen) op de Diensten en alle krachtens de Overeenkomst ontwikkelde materialen zoals documentatie en offertes, alsmede voorbereidend materiaal daarvan, berusten uitsluitend bij Leading Apps of haar licentiegevers. De Klant verkrijgt uitsluitend de gebruiksrechten en bevoegdheden die bij deze algemene voorwaarden of anderszins uitdrukkelijk worden toegekend en voor het overige zal hij de Diensten en/of alle krachtens de Overeenkomst ontwikkelde materialen niet verveelvoudigen of daarvan kopieën vervaardigen.</li>
                                            <li>Leading Apps zal de Klant vrijwaren tegen elke rechtsvordering die gebaseerd is op de bewering dat door Leading Apps zelf ontwikkelde materialen inbreuk maken op een in Nederland geldend recht van intellectueel of industrieel eigendom, onder de voorwaarde dat de Klant Leading Apps onverwijld schriftelijk informeert over het bestaan en de inhoud van de rechtsvordering en de afhandeling van de zaak, waaronder het treffen van eventuele schikkingen, geheel overlaat aan Leading Apps.</li>
                                            <li>De Klant zal daartoe de nodige volmachten, informatie en medewerking aan Leading Apps verlenen om zich, zo nodig in naam van de Klant, tegen deze rechtsvorderingen te verweren.</li>
                                            <li>Deze verplichting tot vrijwaring vervalt indien en voor zover de betreffende inbreuk verband houdt met wijzigingen die de Klant in de materialen heeft aangebracht of door derden heeft laten aanbrengen.</li>
                                            <li>Indien in rechte onherroepelijk vast staat dat de door Leading Apps zelf ontwikkelde materialen inbreuk maken op enig aan een derde toebehorend recht van intellectueel of industrieel eigendom of indien naar het oordeel van Leading Apps een gerede kans bestaat dat een zodanige inbreuk zich voordoet, zal Leading Apps, zulks ter harer keuze:
                                                <ul>
                                                    <li>zorgdragen dat de Klant de ter beschikking gestelde, of functioneel gelijkwaardige andere materialen, ongestoord kan blijven gebruiken; of</li>
                                                    <li>gerechtigd zijn de Overeenkomst te beëindigen, tegen creditering van de door de Klant betaalde vergoeding onder aftrek van een redelijke gebruiksvergoeding.</li>
                                                </ul>
                                            </li>
                                            <li>Iedere andere of verdergaande aansprakelijkheid of vrijwaringsverplichting van Leading Apps wegens schending van rechten van intellectueel of industrieel eigendom van derden is uitgesloten, daaronder begrepen aansprakelijkheid en vrijwaring verplichtingen van Leading Apps voor inbreuken die veroorzaakt worden door het gebruik van de materialen in een niet door Leading Apps gemodificeerde vorm, in samenhang met niet door Leading Apps geleverde of verstrekte zaken of programmatuur of op een andere wijze dan waarvoor de door Leading Apps verstrekte materialen zijn ontwikkeld of bestemd. </li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">9. Aansprakelijkheid</h2>
                                        <ol>
                                            <li>Het gebruik door Klant van de Diensten van Leading Apps is geheel voor eigen risico van de Klant. Leading Apps kan niet verantwoordelijk worden gehouden voor de inhoud, inclusief nauwkeurigheid, wettigheid, volledigheid, etc. van de informatie die de Klant ontvangt of verzendt via internet. Leading Apps kan daarom niet aansprakelijk worden gesteld voor verlies direct of indirect -immateriële schennis of andere omstandigheden die voortkomen uit het gebruik van informatie die te vinden is op internet.</li>
                                            <li>Leading Apps is niet aansprakelijk voor schade die de Klant lijdt door tekortkomingen van Leading Apps bij de uitvoering van de Overeenkomst. Met name kan Leading Apps niet aansprakelijk worden gesteld voor verliezen of andere omstandigheden die zich kunnen voordoen als gevolg van de niet beschikbaarheid van toegang tot diensten of informatie op internet, een gebrek in de beveiliging van opgeslagen gegevens van de Klant, handelingen van andere klanten of internetgebruikers, wijzigingen in inbelnummers, log-in-procedure, account en e­ mailadres, ongeacht de redenen hiervoor.</li>
                                            <li>Aansprakelijkheid van Leading Apps voor indirecte schade, daaronder begrepen gevolgschade, gederfde winst, gemiste besparingen en schade door bedrijfsstagnatie, is uitgesloten.</li>
                                            <li>Leading Apps accepteert geen aansprakelijkheid voor enig verlies van persoonlijke gegevens, inclusief het verlies of schade van geïnstalleerde software, etc.</li>
                                            <li>Leading Apps accepteert geen aansprakelijkheid voor toegang die is verworven door onbevoegde personen tot de gegevens of systemen van de Klant of schade als gevolg hiervan.</li>
                                            <li>De Klant is verantwoordelijk voor alle kosten die betrekking hebben op bestellingen voor diensten via internet en het gebruik van betaalsystemen op internet.</li>
                                            <li>Bij opzegging/verhuizing/verwijdering van een domeinnaam vallen de toekomstige activiteiten van het domein niet meer onder de verantwoordelijkheid van Leading Apps en Leading Apps kan hiervoor ook niet langer aansprakelijk worden gesteld.</li>
                                            <li>De Klant die handelt in strijd met zijn verplichtingen onder de Overeenkomst of deze algemene voorwaarden is aansprakelijk voor alle daaruit voor Leading Apps voortvloeiende schade.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">10. Duur en einde van de overeenkomst</h2>
                                        <ol>
                                            <li>Tenzij uitdrukkelijk anders overeengekomen in de Overeenkomst, worden de diensten het eerste jaar altijd afgenomen voor de periode vanaf de aanvangsdatum t/m het einde van het daaropvolgende kalenderjaar. Na deze periode wordt de overeenkomst telkens met een (1) jaar stilzwijgend verlengd.</li>
                                            <li>Opzeggingen in het eerste jaar dienen binnen te zijn voor 30 november van het jaar volgend op het jaar waarin de Overeenkomst is aangegaan. Opzegging van de Overeenkomst na het eerste jaar dienen uiterlijk 30 november van dat jaar bij Leading Apps gedaan te zijn. In beide gevallen dient de opzegging schriftelijk te geschieden aan het adres van Leading Apps.</li>
                                            <li>Indien de registrerende instantie een (aanvullende) procedure hanteert of de invulling van documenten vereist voor de beëindiging van een Domeinnaam registratie kunnen geregistreerde Domeinnamen enkel opgezegd worden met de door de registrerende instantie aangewezen documenten en/of procedures.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">11. Facturatie en betaling</h2>
                                        <ol>
                                            <li>De door de Klant aan Leading Apps verschuldigde kosten worden telkens per jaar vooraf aan de Klant in rekening gebracht.</li>
                                            <li>Betaling van facturen dient te geschieden binnen veertien (14) dagen na factuurdatum, op een door Leading Apps aan te geven wijze in de valuta waarin is gefactureerd.</li>
                                            <li>Na het verstrijken van veertien (14) dagen na de factuurdatum verkeert de Klant van rechtswege in verzuim zonder dat nadere ingebrekestelling daartoe is vereist.</li>
                                            <li>De Klant is vanaf het moment van in verzuim treden over het opeisbare bedrag een rente verschuldigd van 1% per maand, tenzij de wettelijke (handels)rente hoger is in welk geval de wettelijke (handels)rente geldt. Alle (buiten)gerechtelijke kosten welke Leading Apps maakt ter verkrijging van voldoening - zowel in als buiten rechte - komen vanaf dat moment voor rekening van de Klant. In dat geval is de Klant een vergoeding verschuldigd van ten minste 10% van het openstaande bedrag, met een minimum van€ 75,00 (zegge: vijfenzeventig euro). Indien de werkelijk door Leading Apps gemaakte en te maken kosten boven dit bedrag uitstijgen, komen deze eveneens voor vergoeding in aanmerking.</li>
                                            <li>In geval van liquidatie, faillissement of surseance van betaling van de Klant zijn de vorderingen van Leading Apps en de verplichtingen van de wederpartij jegens Leading Apps onmiddellijk opeisbaar.</li>
                                            <li>Door de Klant gedane betalingen strekken steeds ter afdoening in de eerste plaats van alle verschuldigde rente en kosten, in de tweede plaats van opeisbare facturen die het langst openstaan, zelfs al vermeldt de Klant dat de voldoening betrekking heeft op een latere factuur.</li>
                                            <li>Leading Apps is gerechtigd volledige aflossing van de hoofdsom te weigeren, indien daarbij niet eveneens de opengevallen en lopende rente alsmede de kosten worden voldaan.</li>
                                            <li>Indien met Klant meerdere (rechts)personen of ondernemingen worden aangeduid, zullen deze hoofdelijk gehouden zijn tot nakoming van alle verplichtingen uit de met Leading Apps gesloten Overeenkomst.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">12. Opschorting en ontbinding</h2>
                                        <ol>
                                            <li>Indien de Klant in gebreke blijft aan haar verplichtingen uit de Overeenkomst te voldoen, is Leading Apps, onverminderd hetgeen daaromtrent in de Overeenkomst is bepaald, gerechtigd de Overeenkomst door middel van een aangetekend schrijven buitengerechtelijk te ontbinden. De ontbinding zal pas plaatsvinden nadat de Klant schriftelijk in gebreke is gesteld en een redelijke termijn is geboden om de tekortkoming te zuiveren.</li>
                                            <li>Voorts is Leading Apps gerechtigd, zonder dat enige aanmaning of ingebrekestelling zal zijn vereist, de Overeenkomst door middel van een aangetekend schrijven buitengerechtelijke en met onmiddellijke ingang geheel of gedeeltelijk te ontbinden indien:
                                                <ul>
                                                    <li>de Klant (voorlopige) surseance van betaling aanvraagt of aan de Klant (voorlopige) surseance van betaling wordt verleend;</li>
                                                    <li>de Klant zijn eigen faillissement aanvraagt of in staat van faillissement wordt verklaard;</li>
                                                    <li>de onderneming van de Klant wordt geliquideerd;</li>
                                                    <li>een belangrijk gedeelte van de onderneming van de Klant wordt overgenomen;</li>
                                                    <li>de Klant zijn huidige onderneming staakt;</li>
                                                    <li>buiten toedoen van Leading Apps op een aanmerkelijk deel van het vermogen van de Klant beslag wordt gelegd, dan wel indien de Klant anderszins niet langer in staat moet worden geacht de verplichtingen uit de Overeenkomst na te kunnen komen.</li>
                                                </ul>
                                            </li>
                                            <li>Bedragen die Leading Apps voor de ontbinding aan de Klant heeft gefactureerd in verband met hetgeen Leading Apps reeds ter uitvoering van de Overeenkomst heeft gepresteerd, alsmede op grond van de Overeenkomst reeds uitgevoerde maar nog niet gefactureerde werkzaamheden, blijven onverminderd door de Klant aan Leading Apps verschuldigd en worden op het moment van de ontbinding direct opeisbaar.</li>
                                            <li>Indien de Klant, na deswege in gebreke te zijn gesteld, enige verplichting voortvloeiende uit de Overeenkomst niet, niet volledig of niet tijdig nakomt, is Leading Apps gerechtigd haar verplichtingen jegens de Klant op te schorten, zonder daardoor tot enige schadevergoeding jegens de Klant gehouden te zijn. Hiertoe is Leading Apps eveneens gerechtigd in de onder lid 2 van dit artikel bedoelde omstandigheden.</li>
                                            <li>In geval van liquidatie, van (aanvrage van) surseance van betaling of faillissement, van beslaglegging - indien en voor zover het beslag niet binnen drie maanden is opgeheven - ten laste van de Opdrachtgever, van schuldsanering of een andere omstandigheid waardoor de Opdrachtgever niet langer vrijelijk over zijn vermogen kan beschikken, staat het Leading Apps vrij om de overeenkomst terstond en met directe ingang op te zeggen danwel de order of overeenkomst te annuleren, zonder enige verplichting zijnerzijds tot betaling van enige schadevergoeding of schadeloosstelling. De vorderingen van Leading Apps op de Opdrachtgever zijn in dat geval onmiddellijk opeisbaar.</li>
                                            <li>Indien de Opdrachtgever een geplaatste order geheel of gedeeltelijk annuleert, dan zullen de werkzaamheden die werden verricht en de daarvoor bestelde of gereedgemaakte zaken, vermeerderd met de eventuele aan- afvoer- en afleveringskosten daarvan en de voor de uitvoering van de overeenkomst gereserveerde arbeidstijd, integraal aan de Opdrachtgever in rekening worden gebracht.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">13. Beschikbaarheid van de dienst</h2>
                                        <ol>
                                            <li>Leading Apps zal zich inspannen om ononderbroken beschikbaarheid van haar Systeem en netwerk te realiseren, en om toegang tot door Leading Apps opgeslagen data te realiseren, maar biedt hierover geen garanties tenzij anders is overeengekomen in de offerte dan wel de elektronische bestelprocedure middels een als zodanig aangeduide Service Level Agreement (SLA). Voor zover niet in een dergelijke SLA anders is bepaald, geldt voor de beschikbaarheid het in dit artikel overigens bepaalde.</li>
                                            <li>Leading Apps stelt geen reservekopieën (back-ups) ter beschikking aan de Klant, tenzij de Klant hiervoor een aanvullende SLA heeft afgenomen. </li>
                                            <li>Leading Apps zal zich inspannen om de door haar gebruikte software up-to-date te houden. Leading Apps is hierbij echter afhankelijk van derden. Leading Apps is gerechtigd bepaalde updates of patches niet te installeren als dit naar haar oordeel een correcte levering van de dienst niet ten goede komt.</li>
                                            <li>Leading Apps zal zich inspannen om te zorgen dat Klant gebruik kan maken van de netwerken die direct of indirect verbonden zijn met het netwerk van Leading Apps. Leading Apps kan echter niet garanderen dat deze netwerken op enig moment beschikbaar zijn. Indien naar het oordeel van Leading Apps een gevaar ontstaat voor het functioneren van het Systeem of het netwerk van Leading Apps of derden en/of van de dienstverlening via een netwerk, in het bijzonder door overmatig verzenden van e-mail of andere gegevens, slecht beveiligde systemen of activiteiten van virussen, Trojans en vergelijkbare software, is Leading Apps gerechtigd alle maatregelen te nemen die zij redelijkerwijs nodig acht om dit gevaar af te wenden dan wel te voorkomen.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">14. Overmacht</h2>
                                        <ol>
                                            <li>In geval van overmacht, waaronder in ieder geval wordt verstaan storingen of uitvallen van internet, de telecommunicatie-infrastructuur, synflood, netwerkaanval, DoS of DDoS-attacks, stroomstoringen, binnenlandse onlusten, mobilisatie, oorlog, stremming in het vervoer, staking, uitsluiting, bedrijfsstoornissen, stagnatie in toelevering, brand, overstroming, in- en uitvoer belemmeringen en in het geval dat Leading Apps door zijn eigen leveranciers, ongeacht de reden daartoe, niet tot levering in staat wordt gesteld waardoor nakoming van de Overeenkomst redelijkerwijs niet van Leading Apps kan worden gevergd, zal de uitvoering van de Overeenkomst worden opgeschort, dan wel de Overeenkomst worden beëindigd wanneer de overmachtsituatie langer dan negentig (90) dagen heeft geduurd, alles zonder enige verplichting tot schadevergoeding.</li>
                                            <li>Leading Apps zal zich inspannen om in geval van het niet beschikbaar zijn van de dienst, door storingen, onderhoud of andere oorzaken, de Klant te informeren over de aard en de verwachte duur van de onderbreking.</li>
                                            <li>Leading Apps behoudt zich het recht voor betaling te vorderen van prestaties die bij de uitvoering van de betreffende Overeenkomst zijn verricht, voordat van de overmacht veroorzakende omstandigheid is gebleken.</li>
                                            <li>Leading Apps heeft het recht om haar Systeem, inclusief de Website van de Klant, of gedeelten daarvan tijdelijk buiten gebruik te stellen ten behoeve van onderhoud, aanpassing of verbetering daarvan. Leading Apps zal proberen een dergelijke buitengebruikstelling zoveel mogelijk buiten kantooruren om te laten plaatsvinden en zich inspannen om Klant tijdig op de hoogte stellen van de geplande buitengebruikstelling. Leading Apps is echter nooit aansprakelijk tot schadevergoeding voor schade in verband met zodanige buitengebruikstelling.</li>
                                            <li>Leading Apps heeft het recht om haar Systeem, inclusief de website, of gedeelten daarvan van tijd tot tijd aan te passen om de functionaliteit te verbeteren en om fouten te herstellen. Indien een aanpassing leidt tot een aanzienlijke wijziging in de functionaliteit, zal Leading Apps zich inspannen om de Klant daarvan op de hoogte stellen. In geval van aanpassingen die voor meerdere klanten relevant zijn, is het niet mogelijk om aIleen voor de Klant van een bepaalde aanpassing af te zien. Leading Apps is niet gehouden tot enige vergoeding van schade veroorzaakt door een dergelijke aanpassing.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">15. Overdracht van rechten en plichten</h2>
                                        <p>Het is de Klant niet toegestaan enig recht uit een met Leading Apps gesloten Overeenkomst aan derden over te dragen, anders dan bij overdracht van zijn gehele onderneming.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">16. Vertrouwelijke informatie</h2>
                                        <p>Partijen zijn gehouden feiten en omstandigheden, die in het kader van de opdracht aan de andere partij ter kennis komen, vertrouwelijk te behandelen. Derden, die bij de uitvoering van de opdracht worden betrokken, zullen ten aanzien van deze feiten en omstandigheden afkomstig van de andere partij aan eenzelfde vertrouwelijke behandeling worden gebonden.</p>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">17. Beveiliging en privacy</h2>
                                        <ol>
                                            <li>De Klant geeft door het aangaan van een Overeenkomst toestemming aan Leading Apps zijn Persoonsgegevens te verwerken ten behoeve van de uitvoering van de Overeenkomst en de administratie en beheerstaken van Leading Apps. Deze Persoonsgegevens zijn slechts toegankelijk voor Leading Apps en worden niet aan derden verstrekt, tenzij Leading Apps hiertoe krachtens de wet of een rechterlijke uitspraak verplicht is.</li>
                                            <li>Leading Apps onthoudt zich van het inzien van (persoonlijke) e-mail en/of bestanden van Klanten en stelt deze niet ter beschikking van derden, tenzij Leading Apps hiertoe krachtens de wet of een rechterlijke uitspraak verplicht is, dan wel in het geval de Klant handelt (of wordt vermoed te handelen) in strijd met artikel 5 van deze algemene voorwaarden.</li>
                                            <li>Voorzover Leading Apps Persoonsgegevens ten behoeve van de Klant verwerkt, doet Leading Apps dit als bewerker van Persoonsgegevens in de zin van de Wet Bescherming Persoonsgegevens (WBP). De Klant zal aan alle verplichtingen voldoen die op hem als de verantwoordelijke in de zin van de WBP voor deze gegevensverwerking rusten en vrijwaart Leading Apps tegen elke actie die is gebaseerd op het niet voldoen aan deze verplichtingen.</li>
                                            <li>Indien door de Klant middels de Leading Apps Diensten Persoonsgegevens naar landen buiten de Europese Unie worden getransporteerd, vrijwaart de Klant Leading Apps tegen alle rechtsvorderingen van derden die zijn gebaseerd op de bewering dat deze Persoonsgegevens worden uitgevoerd in strijd met de bepalingen van de WBP.</li>
                                            <li>Leading Apps legt passende technische en organisatorische maatregelen ten uitvoer om de Persoonsgegevens te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking. Deze maatregelen garanderen, rekening houdend met de stand van de techniek en de kosten van de tenuitvoerlegging, een passend beveiligingsniveau gelet op de risico's die de verwerking en de aard van te beschermen gegevens met zich meebrengen.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">18. Wijziging en vindplaats</h2>
                                        <ol>
                                            <li>Leading Apps behoudt het recht deze algemene hosting voorwaarden te wijzigen of aan te vullen.</li>
                                            <li>Wijzigingen gelden ook ten aanzien van reeds gesloten overeenkomsten met inachtneming van een termijn van dertig (30) dagen na schriftelijke bekendmaking van de wijziging.</li>
                                            <li>Indien de Klant niet akkoord gaat met de gewijzigde algemene voorwaarden, is hij gerechtigd de Overeenkomst te ontbinden met ingang van de wijzigingsdatum van de algemene voorwaarden of binnen zeven (7) dagen na de ontvangstdatum van de wijziging van de algemene voorwaarden indien deze ontvangstdatum ligt na de ingangsdatum van de wijziging.</li>
                                            <li>Deze voorwaarden kunnen worden ingezien, gedownload en opgeslagen via: https://leadingapps.nl/#voorwaarden. Van toepassing is steeds de laatst online geplaatste versie.</li>
                                        </ol>
                                    </div>

                                    <div className="mt-5 pt-3">
                                        <h2 className="bold mb-3 ">19. Toepasselijk recht en forumkeuze</h2>
                                        <ol>
                                            <li>Op de Overeenkomst tussen Leading Apps en de Klant gesloten is het Nederlands recht van toepassing.</li>
                                            <li>Geschillen die ontstaan naar aanleiding van een Overeenkomst waarop deze algemene voorwaarden geheel of gedeeltelijk van toepassing zijn, of naar aanleiding van andere overeenkomsten die een uitvloeisel zijn van een dergelijke Overeenkomst, zullen worden beslecht door de bevoegde rechter van het arrondissement van de vestigingsplaats van Leading Apps, tenzij een dwingende wetsbepaling zich hiertegen verzet. Dit laat onverlet dat Leading Apps met de Klant kan overeenkomen het geschil te laten beslechten door middel van onafhankelijke arbitrage.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-2 mb-4 momt-2">
                        <div className="row g-0">
                            <Link to="/voorwaarden" >
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("voorwaarden")}>Algemene voorwaarden</h6>
                                </div>
                            </Link>
                            <Link to="/hosting-voorwaarden">
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("hosting-voorwaarden")}>Hosting voorwaarden</h6>
                                </div>
                            </Link>
                            <Link to="/privacy" className="voorwaarden-link-wrapper">
                                <div className="col-12 mh-centered mb-3">
                                    <h6 className={"black voorwaarden-link " + isSelected("privacy")}>Privacyverklaring</h6>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />
        </>
    )
}

export default HostingVoorwaarden;